import React from "react";
import { Box } from "@mui/material";
import LightIcon from "@mui/icons-material/Light";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import TheaterComedyRoundedIcon from "@mui/icons-material/TheaterComedyRounded";

const Shape = (propse) => {
  return (
    <Box
      className="Shape"
      sx={{
        display:
          propse.Appmenu === "1" &&
          (propse.Appsubmenu === "11" || propse.Appsubmenu === "12")
            ? "none"
            : propse.Appmenu === "2" &&
              (propse.Appsubmenu === "21" ||
                propse.Appsubmenu === "22" ||
                propse.Appsubmenu === "23" ||
                propse.Appsubmenu === "AddUben")
            ? "none"
            : propse.Appmenu === "3" &&
              (propse.Appsubmenu === "31" || propse.Appsubmenu === "32")
            ? "none"
            : "flex",
      }}
    >
      <Box
        className="Shape-Box"
        onClick={() => propse.setAppsubmenu(`${propse.Appmenu}1`)}
      >
        {propse.Appmenu === "1" ? (
          <LightIcon sx={{ fontSize: "2.5rem" }} />
        ) : propse.Appmenu === "2" ? (
          <DirectionsBikeIcon sx={{ fontSize: "2.5rem" }} />
        ) : (
          <TheaterComedyRoundedIcon sx={{ fontSize: "2.5rem" }} />
        )}
        <span>
          beginne zu{" "}
          {propse.Appmenu === "1"
            ? "VERB"
            : propse.Appmenu === "2"
            ? "ÜBEN"
            : "REGELN"}
        </span>
      </Box>
    </Box>
  );
};

export default Shape;
