import { Box, Menu } from "@mui/material";
import Fade from "@mui/material/Fade";
import React from "react";

const Popupshape = (propse) => {
  return (
    <Menu
      id="fade-menu"
      MenuListProps={{
        "aria-labelledby": "fade-menu",
      }}
      anchorEl={propse.anchorEl}
      open={propse.open}
      onClose={propse.handleClose}
      TransitionComponent={Fade}
      PaperProps={{
        style: {
          backgroundColor: "rgba(237, 239, 236,0)",
          boxShadow: "0em 0em 0rem rgba(237, 239, 236,0)",
        },
      }}
    >
      <Box className="Popup" onClick={propse.handleClose}>
        {propse.Text.map((option, index) => (
          <React.Fragment key={index}>
            <span
              className="SHAPESICONS"
              onClick={() => propse.handleShapes(option)}
            >
              {option}
            </span>
            {index === 4 ||
            index === 9 ||
            index === 14 ||
            index === 19 ||
            index === 23 ||
            index === 28 ? (
              <br />
            ) : null}
          </React.Fragment>
        ))}
      </Box>
    </Menu>
  );
};

export default Popupshape;
