import React, { useState } from "react";
import { Box } from "@mui/material";
import Forward from "@mui/icons-material/ArrowForwardIosRounded";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import SearchOffRoundedIcon from "@mui/icons-material/SearchOffRounded";
import Back from "@mui/icons-material/ArrowBackIosRounded";

const SubHeader = (propse) => {
  const HandleOption = (e) => {
    propse.setOptionToSelect("");
    if (e === 1) propse.setAppsubmenu("AddUben");
    if (e === 0) propse.setAppsubmenu("");
  };
  const HandleSearch = (e) => {
    if (e === 1) propse.setSearching(true);
    if (e === 0) {
      propse.setSearching(false);
      propse.setDataHolderSearch([]);
      propse.setSearchtext("");
    }
  };
  const HandleAddRule = () => {
    propse.setAppsubmenu(`${propse.Appmenu}2`);
    propse.setOptionRUle(null);
  };
  return (
    <Box className="SubHeader">
      <Box className="SubHeader-Menus">
        {propse.Appmenu === "1" ? (
          <React.Fragment>
            <Box>
              Verb
              <Forward
                sx={{
                  fontSize: "0.6rem",
                }}
                className="SubHeader-Seperat"
              />
              <span
                onClick={() => propse.setAppsubmenu(`${propse.Appmenu}1`)}
                style={{ color: propse.Appsubmenu === "11" ? "#ed013e" : null }}
              >
                Rezension
              </span>
              <Forward
                sx={{
                  fontSize: "0.6rem",
                }}
                className="SubHeader-Seperat"
              />
              <span
                onClick={() => propse.setAppsubmenu(`${propse.Appmenu}2`)}
                style={{ color: propse.Appsubmenu === "12" ? "#ed013e" : null }}
              >
                Erstellen
              </span>
            </Box>
            <Box>
              <input
                className="SearchInput"
                placeholder="Search ..."
                // value={Searchtext}
                // onChange={onChangeSearch}
              />
            </Box>
          </React.Fragment>
        ) : propse.Appmenu === "2" ? (
          <React.Fragment>
            {propse.Searching ? (
              <input
                className="SearchInput3"
                placeholder="Search ..."
                value={propse.Searchtext}
                onChange={propse.onChangeSearch}
              />
            ) : (
              <Box>
                Üben
                <Forward
                  sx={{
                    fontSize: "0.6rem",
                  }}
                  className="SubHeader-Seperat"
                />
                <span
                  onClick={() => propse.setAppsubmenu(`${propse.Appmenu}1`)}
                  style={{
                    color: propse.Appsubmenu === "21" ? "#ed013e" : null,
                  }}
                >
                  Rezension
                </span>
                <Forward
                  sx={{
                    fontSize: "0.6rem",
                  }}
                  className="SubHeader-Seperat"
                />
                <span
                  onClick={() => propse.setAppsubmenu(`${propse.Appmenu}2`)}
                  style={{
                    color: propse.Appsubmenu === "22" ? "#ed013e" : null,
                  }}
                >
                  Leitner Box
                </span>
                <Forward
                  sx={{
                    fontSize: "0.6rem",
                  }}
                  className="SubHeader-Seperat"
                />
                <span
                  onClick={() => propse.setAppsubmenu(`${propse.Appmenu}3`)}
                  style={{
                    color: propse.Appsubmenu === "23" ? "#ed013e" : null,
                  }}
                >
                  Zufällig
                </span>
              </Box>
            )}
            <Box className="UbenSubMenu">
              {propse.Searching ? (
                <SearchOffRoundedIcon
                  sx={{
                    fontSize: "1rem",
                    color: "#ed013e",
                  }}
                  className="AppsOutlinedIconSearchUben"
                  onClick={() => HandleSearch(0)}
                />
              ) : (
                <SearchRoundedIcon
                  sx={{
                    fontSize: "1rem",
                  }}
                  className="AppsOutlinedIconSearchUben"
                  onClick={() => HandleSearch(1)}
                />
              )}
              {propse.Appsubmenu === "AddUben" ? (
                <Back
                  sx={{ fontSize: "1rem", cursor: "pointer", color: "#ed013e" }}
                  onClick={() => HandleOption(0)}
                />
              ) : (
                <AppsOutlinedIcon
                  sx={{ fontSize: "1rem", cursor: "pointer" }}
                  className="AppsOutlinedIconUben"
                  onClick={() => HandleOption(1)}
                />
              )}
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box>
              Regeln
              <Forward
                sx={{
                  fontSize: "0.6rem",
                }}
                className="SubHeader-Seperat"
              />
              <span
                onClick={() => propse.setAppsubmenu(`${propse.Appmenu}1`)}
                style={{
                  color: propse.Appsubmenu === "31" ? "#ed013e" : null,
                }}
              >
                Set
              </span>
              <Forward
                sx={{
                  fontSize: "0.6rem",
                }}
                className="SubHeader-Seperat"
              />
              <span
                onClick={HandleAddRule}
                style={{
                  color: propse.Appsubmenu === "32" ? "#ed013e" : null,
                }}
              >
                Erstellen
              </span>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </Box>
  );
};

export default SubHeader;
