import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Letters from "../../../components/letters/Letters";
import Button from "../../../context/buttons/Button";
import AXIOS from "../../../context/api/AXIOS";

const AddStatements = (propse) => {
  const [GR, setGR] = useState("");
  const [EN, setEN] = useState("");
  const [SP, setSP] = useState("");
  const [Loading, setLoading] = useState("");
  const HandleAdd = async () => {
    setLoading(true);
    const DATA = {
      ID: propse.Counter,
      GR: GR,
      SP: SP,
      EN: EN,
      Day: 0,
    };
    if (GR.length !== 0 && EN.length !== 0) {
      await AXIOS.put(`/DEEP-GR/${propse.Counter}.json`, DATA)
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setGR("");
            setSP("");
            setEN("");
            propse.GetData_Statement();
          }
        })
        .catch((error) => {
          console.log(error);
          alert(error);
          setLoading(false);
        });
    }
  };
  const HandlEdit = async (IDEdit) => {
    setLoading(true);
    const DATA = {
      ID: IDEdit,
      GR: GR,
      SP: SP,
      EN: EN,
      Day: 0,
    };
    if (GR.length !== 0 && EN.length !== 0) {
      await AXIOS.put(`/DEEP-GR/${IDEdit}.json`, DATA)
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            propse.setAppsubmenu("");
            propse.GetData_Statement();
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          alert(error);
        });
    }
  };
  useEffect(() => {
    if (propse.Option.length !== 0) {
      setGR(propse.Option.GR);
      setEN(propse.Option.EN);
      setSP(propse.Option.SP);
    }
  }, [propse.Option]);
  return (
    <React.Fragment>
      <Box className="Wrppers">
        <Box className="Wrppers-Box">
          <Letters GR={GR} setGR={setGR} />
          <input
            className="InputUben1"
            placeholder="Die erste Aussage"
            value={GR}
            onChange={(e) => setGR(e.target.value)}
          />
          <input
            className="InputUben2"
            placeholder="Der Zauber"
            value={SP}
            onChange={(e) => setSP(e.target.value)}
          />
          <input
            className="InputUben3"
            placeholder="Die zweite Aussage"
            value={EN}
            onChange={(e) => setEN(e.target.value)}
          />
          {Loading ? (
            <Box className="ADD">
              <Button Title="Loading ..." W="135" H="45" C="#ff023f" R="0" />
            </Box>
          ) : propse.Option.length === 0 ? (
            <Box className="ADD">
              <Button
                Title="ADD"
                W="135"
                H="45"
                C="#ff023f"
                R="0"
                onClick={HandleAdd}
              />
            </Box>
          ) : (
            <Box className="ADD">
              <Button
                Title="EDIT"
                W="135"
                H="45"
                C="#ff023f"
                R="0"
                onClick={() => HandlEdit(propse.Option.ID)}
              />
            </Box>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default AddStatements;
