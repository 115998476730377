import React from "react";
import "./Button.css";
import { Box } from "@mui/material";

const Button = (propse) => {
  return (
    <React.Fragment>
      <Box
        className="Button"
        sx={{
          width: `${propse.W}px`,
          minHeight: `${propse.H}px`,
          backgroundColor: propse.C,
          borderRadius: `${propse.R}px`,
        }}
        onClick={propse.onClick}
      >
        <span className="Button-Title">{propse.Title}</span>
      </Box>
    </React.Fragment>
  );
};

export default Button;
