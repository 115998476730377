import { Box } from "@mui/material";
import React, { useState } from "react";
import LightIcon from "@mui/icons-material/Light";
import Letters from "../../../components/letters/Letters";
import Button from "../../../context/buttons/Button";
import AXIOS from "../../../context/api/AXIOS";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

const Addverb = (propse) => {
  const [GR, setGR] = useState("");
  const [ICH, setICH] = useState("");
  const [DU, setDU] = useState("");
  const [ER, setER] = useState("");
  const [WIR, setWIR] = useState("");
  const [IHR, setIHR] = useState("");
  const [SIE, setSIE] = useState("");
  const [GRI, setGRI] = useState("");
  const [DOK, setDOK] = useState("Präsens");
  const [Loading, setLoading] = useState(false);
  const [Select, setDelect] = useState(0);
  const [Perfekt, setPerfekt] = useState(0);
  const HandleAdd = async () => {
    if (
      GR.length !== 0 &&
      ICH.length !== 0 &&
      DU.length !== 0 &&
      ER.length !== 0 &&
      WIR.length !== 0 &&
      IHR.length !== 0 &&
      SIE.length !== 0
    ) {
      const DATA = {
        ID:
          DOK === "Präsens"
            ? propse.Server_Verbs === null
              ? 0
              : propse.Server_Verbs.length
            : null,
        GR: DOK === "Präsens" ? GR.trim() : null,
        ICH: ICH.trim(),
        DU: DU.trim(),
        ER: ER.trim(),
        WIR: WIR.trim(),
        IHR: IHR.trim(),
        SIE: SIE.trim(),
      };
      propse.Server_Verbs_Oject.push(DATA);
      if (DOK !== "Perfekt") {
        setICH("");
        setDU("");
        setER("");
        setWIR("");
        setIHR("");
        setSIE("");
        if (DOK === "Präsens") setDOK("Präteritum");
        if (DOK === "Präteritum") setDOK("Futur");
        if (DOK === "Futur") setDOK("Perfekt");
      }
      if (DOK === "Perfekt") {
        setLoading(true);
        await AXIOS.put(
          `/DEEP-GR-VERBS/${
            propse.Server_Verbs === null ? 0 : propse.Server_Verbs.length
          }.json`,
          propse.Server_Verbs_Oject
        )
          .then((response) => {
            if (response.status === 200) {
              setLoading(false);
              propse.setAppsubmenu("11");
              propse.GetData_Verbs();
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            alert(error);
          });
      }
    }
  };
  const HandleSet = () => {
    if (DOK === "Futur" && GRI.length !== 0) {
      setICH(`werde ${GRI} . viyade `);
      setDU(`wirst ${GRI} . vest`);
      setER(`wird ${GRI} . vet `);
      setWIR(`werden ${GRI} . viyaden `);
      setIHR(`werdet ${GRI} . viyadet `);
      setSIE(`werden ${GRI} . viyaden `);
    }
    if (DOK === "Perfekt" && GRI.length !== 0) {
      setICH(
        Perfekt === 1
          ? `habe ${GRI} . habe `
          : Perfekt === 2
          ? `bin ${GRI} . ben `
          : `hatte ${GRI} . hate `
      );
      setDU(
        Perfekt === 1
          ? `hast ${GRI} . hast `
          : Perfekt === 2
          ? `bist ${GRI} . best `
          : `hattest ${GRI} . hatest `
      );
      setER(
        Perfekt === 1
          ? `hat ${GRI} . hat `
          : Perfekt === 2
          ? `ist ${GRI} . est `
          : `hatte ${GRI} . hate `
      );
      setWIR(
        Perfekt === 1
          ? `haben ${GRI} . haben `
          : Perfekt === 2
          ? `sind ${GRI} . zent `
          : `hatten ${GRI} . haten `
      );
      setIHR(
        Perfekt === 1
          ? `habt ${GRI} . habt `
          : Perfekt === 2
          ? `seid ${GRI} . zait `
          : `hattet ${GRI} . hatet `
      );
      setSIE(
        Perfekt === 1
          ? `haben ${GRI} . haben `
          : Perfekt === 2
          ? `sind ${GRI} . zent `
          : `hatten ${GRI} . haten `
      );
    }
  };
  return (
    <React.Fragment>
      <Box className="Wrppers">
        <Box className="Wrppers-Box">
          <Letters
            GR={
              Select === 0
                ? GR
                : Select === 1
                ? ICH
                : Select === 2
                ? DU
                : Select === 3
                ? ER
                : Select === 4
                ? WIR
                : Select === 5
                ? IHR
                : Select === 6
                ? SIE
                : Select === 7
                ? GRI
                : GR
            }
            setGR={
              Select === 0
                ? setGR
                : Select === 1
                ? setICH
                : Select === 2
                ? setDU
                : Select === 3
                ? setER
                : Select === 4
                ? setWIR
                : Select === 5
                ? setIHR
                : Select === 6
                ? setSIE
                : Select === 7
                ? setGRI
                : setGR
            }
          />
          <Box className="Recordes">
            <LightIcon />
            <input
              className="Input1"
              placeholder="Infinitive . Fluch . Englisch"
              value={GR}
              onChange={(e) => setGR(e.target.value)}
              onFocus={() => setDelect(0)}
            />
          </Box>
          <Box className="Set-Verbs">
            {DOK === "Futur" ? (
              <React.Fragment>
                <CheckRoundedIcon
                  sx={{
                    fontSize: "1.2rem",
                    cursor: "pointer",
                  }}
                  onClick={HandleSet}
                />
                <input
                  className="Input8"
                  placeholder="Infinitiv Futur"
                  value={GRI}
                  onChange={(e) => setGRI(e.target.value)}
                  onFocus={() => setDelect(7)}
                />
              </React.Fragment>
            ) : DOK === "Perfekt" ? (
              <React.Fragment>
                <CheckRoundedIcon
                  sx={{
                    fontSize: "1.2rem",
                    cursor: "pointer",
                  }}
                  onClick={HandleSet}
                />
                <span
                  className="Set-Verb-Perfekt"
                  onClick={() => setPerfekt(1)}
                  style={{ opacity: Perfekt === 1 ? "1" : "0.5" }}
                >
                  Haben
                </span>
                <span
                  className="Set-Verb-Perfekt"
                  onClick={() => setPerfekt(2)}
                  style={{ opacity: Perfekt === 2 ? "1" : "0.5" }}
                >
                  Sein
                </span>
                <span
                  className="Set-Verb-Perfekt"
                  onClick={() => setPerfekt(3)}
                  style={{ opacity: Perfekt === 3 ? "1" : "0.5" }}
                >
                  Hatten
                </span>
                <input
                  className="Input9"
                  placeholder="Infinitiv Futur"
                  value={GRI}
                  onChange={(e) => setGRI(e.target.value)}
                  onFocus={() => setDelect(7)}
                />
              </React.Fragment>
            ) : null}
          </Box>
          <Box className="Recordes">
            <span className="SPP">
              {"{"}I{"}"}
            </span>
            Ich
            <input
              className="Input2"
              placeholder="..."
              value={ICH}
              onChange={(e) => setICH(e.target.value)}
              onFocus={() => setDelect(1)}
            />
          </Box>
          <Box className="Recordes">
            <span className="SPP">
              {"{"}you informal{"}"}
            </span>
            Du
            <input
              className="Input3"
              placeholder="..."
              value={DU}
              onChange={(e) => setDU(e.target.value)}
              onFocus={() => setDelect(2)}
            />
          </Box>
          <Box className="Recordes">
            <span className="SPP">
              {"{"}he, she, it{"}"}
            </span>
            Er, Sie, Es
            <input
              className="Input4"
              placeholder="..."
              value={ER}
              onChange={(e) => setER(e.target.value)}
              onFocus={() => setDelect(3)}
            />
          </Box>
          <Box className="Recordes">
            <span className="SPP">
              {"{"}we{"}"}
            </span>
            Wir
            <input
              className="Input5"
              placeholder="..."
              value={WIR}
              onChange={(e) => setWIR(e.target.value)}
              onFocus={() => setDelect(4)}
            />
          </Box>
          <Box className="Recordes">
            <span className="SPP">
              {"{"}you plural{"}"}
            </span>
            Ihr
            <input
              className="Input6"
              placeholder="..."
              value={IHR}
              onChange={(e) => setIHR(e.target.value)}
              onFocus={() => setDelect(5)}
            />
          </Box>
          <Box className="Recordes">
            <span className="SPP">
              {"{"}you formal, they{"}"}
            </span>
            sie, Sie
            <input
              className="Input7"
              placeholder="..."
              value={SIE}
              onChange={(e) => setSIE(e.target.value)}
              onFocus={() => setDelect(6)}
            />
          </Box>
          <Box className="Sun-Buttons">
            {Loading ? (
              <Button Title="Loading ... " W="135" H="45" C="#ff023f" R="0" />
            ) : (
              <Button
                Title={DOK}
                W="135"
                H="45"
                C="#ff023f"
                R="0"
                onClick={HandleAdd}
              />
            )}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Addverb;
