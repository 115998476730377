import { Box } from "@mui/material";
import React from "react";
import Sun from "@mui/icons-material/LightModeRounded";
import Button from "../../context/buttons/Button";

const SunWrapper = ({ HandleSunWrapper }) => {
  return (
    <Box className="Sun-Wrapper">
      <Sun sx={{ fontSize: "3rem" }} className="Sun-Icon" />
      <span className="Sun-Title">Hast du eine Idee im Kopf!</span>
      <span className="Sun-subTitle">Jetzt einfach</span>
      <span className="Sun-subTitle2">anfangen</span>
      <Box className="Sun-Buttons">
        <Button
          Title="Üben"
          W="100"
          H="45"
          C="#16615c"
          R="5"
          onClick={HandleSunWrapper}
        />
      </Box>
    </Box>
  );
};

export default SunWrapper;
