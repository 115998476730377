import { Box } from "@mui/material";
import React, { useState } from "react";
import DialpadIcon from "@mui/icons-material/Dialpad";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import Popup from "../../components/popup/Popup";

const Statements = ({
  option,
  index,
  Ref,
  ServerUpdate,
  Handle,
  setAppsubmenu,
  setOptionToSelect,
  RefIndex,
}) => {
  const [TitleAbber, setTitleAbber] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, Title) => {
    setAnchorEl(event.currentTarget);
    setTitleAbber(Title);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const HandleOptionToSelect = (option) => {
    setOptionToSelect(option);
    setAppsubmenu("AddUben");
  };
  return (
    <React.Fragment>
      <Popup
        Text={TitleAbber}
        anchorEl={anchorEl}
        open={open}
        handleClick={handleClick}
        handleClose={handleClose}
      />
      <Box className="GR">
        <Box
          className="GRT"
          ref={Ref[index]}
          onClick={() => (index === ServerUpdate.length - 1 ? Handle() : null)}
          sx={{
            backgroundColor:
              index === RefIndex
                ? "rgb(255, 2, 63, 0.5)"
                : "rgb(70, 70, 70, 0.2)",
          }}
        >
          {option.GR}
        </Box>
        <DialpadIcon
          sx={{ fontSize: "0.9rem" }}
          className="Icons"
          onClick={() => HandleOptionToSelect(option)}
        />
        <HeadsetMicIcon
          sx={{ fontSize: "0.9rem" }}
          className="Icons"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(event) =>
            option.SP.length === 0 ? null : handleClick(event, option.SP)
          }
        />
      </Box>
      <Box className="EN">
        {index === ServerUpdate.length - 1 ? null : (
          <Box className="ENT">{option.EN}</Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default Statements;
