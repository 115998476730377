import { Box } from "@mui/material";
import React, { useState } from "react";
import LightIcon from "@mui/icons-material/Light";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import Popup from "../../../components/popup/Popup";

const Viewverb = (propse) => {
  const [CounterVVT, setCounterVVT] = useState(0);
  const [Counter, setCounter] = useState(0);
  const HandleVVT = () => {
    if (CounterVVT === 3) {
      setCounterVVT(0);
    } else {
      setCounterVVT(CounterVVT + 1);
    }
  };
  const HandleNext = () => {
    console.log(Counter);
    if (Counter < propse.Server_Verbs.length - 1) {
      setCounter(Counter + 1);
    }
  };
  const HandleBack = () => {
    if (Counter >= 0) {
      setCounter(Counter - 1);
    }
  };
  const [TitleAbber, setTitleAbber] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, Title) => {
    setAnchorEl(event.currentTarget);
    setTitleAbber(Title);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Popup
        Text={TitleAbber}
        anchorEl={anchorEl}
        open={open}
        handleClick={handleClick}
        handleClose={handleClose}
      />
      <Box className="Wrppers">
        <Box className="Wrppers-Box">
          <Box className="Recordes" sx={{ marginTop: "15px" }}>
            <LightIcon />
            <span
              className="Verb-Title"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) =>
                propse.Server_Verbs[Counter][0].GR.split(".")[1] === undefined
                  ? null
                  : propse.Server_Verbs[Counter][0].GR.split(".")[1].length ===
                    0
                  ? null
                  : handleClick(
                      event,
                      propse.Server_Verbs[Counter][0].GR.split(".")[1]
                    )
              }
            >
              {propse.Server_Verbs[Counter][0].GR.split(".")[0]} ➛ to{" "}
              {propse.Server_Verbs[Counter][0].GR.split(".")[2]}
            </span>
          </Box>
          <Box className="Recordes" sx={{ marginTop: "15px" }}>
            <span className="View-Verb-Title" onClick={HandleVVT}>
              {Counter > 0 ? (
                <ArrowBackIosRoundedIcon
                  sx={{ fontSize: "1rem" }}
                  className="View-Verb-Back"
                  onClick={HandleBack}
                />
              ) : null}
              {CounterVVT === 0
                ? "Präsens"
                : CounterVVT === 1
                ? "Präteritum"
                : CounterVVT === 2
                ? "Futur"
                : CounterVVT === 3
                ? "Perfekt"
                : null}
            </span>
          </Box>
          <Box className="Recordes" sx={{ marginTop: "3px" }}>
            <span className="SPP">
              {"{"}I{"}"}
            </span>
            <span className="SPP4">Ich </span>
            <span
              className="SPP3"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) =>
                propse.Server_Verbs[Counter][CounterVVT].ICH.split(".")[1] ===
                undefined
                  ? null
                  : propse.Server_Verbs[Counter][CounterVVT].ICH.split(".")[1]
                      .length === 0
                  ? null
                  : handleClick(
                      event,
                      propse.Server_Verbs[Counter][CounterVVT].ICH.split(".")[1]
                    )
              }
            >
              {propse.Server_Verbs[Counter][CounterVVT].ICH.split(".")[0]}
            </span>
          </Box>
          <Box className="Recordes" sx={{ marginTop: "3px" }}>
            <span className="SPP">
              {"{"}you informal{"}"}
            </span>
            <span className="SPP4">Du </span>
            <span
              className="SPP3"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) =>
                propse.Server_Verbs[Counter][CounterVVT].DU.split(".")[1] ===
                undefined
                  ? null
                  : propse.Server_Verbs[Counter][CounterVVT].DU.split(".")[1]
                      .length === 0
                  ? null
                  : handleClick(
                      event,
                      propse.Server_Verbs[Counter][CounterVVT].DU.split(".")[1]
                    )
              }
            >
              {propse.Server_Verbs[Counter][CounterVVT].DU.split(".")[0]}
            </span>
          </Box>
          <Box className="Recordes" sx={{ marginTop: "3px" }}>
            <span className="SPP">
              {"{"}he, she, it{"}"}
            </span>
            <span className="SPP4">Er, Sie, Es </span>
            <span
              className="SPP3"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) =>
                propse.Server_Verbs[Counter][CounterVVT].ER.split(".")[1] ===
                undefined
                  ? null
                  : propse.Server_Verbs[Counter][CounterVVT].ER.split(".")[1]
                      .length === 0
                  ? null
                  : handleClick(
                      event,
                      propse.Server_Verbs[Counter][CounterVVT].ER.split(".")[1]
                    )
              }
            >
              {propse.Server_Verbs[Counter][CounterVVT].ER.split(".")[0]}
            </span>
          </Box>
          <Box className="Recordes" sx={{ marginTop: "3px" }}>
            <span className="SPP">
              {"{"}we{"}"}
            </span>
            <span className="SPP4">Wir </span>
            <span
              className="SPP3"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) =>
                propse.Server_Verbs[Counter][CounterVVT].WIR.split(".")[1] ===
                undefined
                  ? null
                  : propse.Server_Verbs[Counter][CounterVVT].WIR.split(".")[1]
                      .length === 0
                  ? null
                  : handleClick(
                      event,
                      propse.Server_Verbs[Counter][CounterVVT].WIR.split(".")[1]
                    )
              }
            >
              {propse.Server_Verbs[Counter][CounterVVT].WIR.split(".")[0]}
            </span>
          </Box>
          <Box className="Recordes" sx={{ marginTop: "3px" }}>
            <span className="SPP">
              {"{"}you plural{"}"}
            </span>
            <span className="SPP4">Ihr </span>
            <span
              className="SPP3"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) =>
                propse.Server_Verbs[Counter][CounterVVT].IHR.split(".")[1] ===
                undefined
                  ? null
                  : propse.Server_Verbs[Counter][CounterVVT].IHR.split(".")[1]
                      .length === 0
                  ? null
                  : handleClick(
                      event,
                      propse.Server_Verbs[Counter][CounterVVT].IHR.split(".")[1]
                    )
              }
            >
              {propse.Server_Verbs[Counter][CounterVVT].IHR.split(".")[0]}
            </span>
          </Box>
          <Box className="Recordes" sx={{ marginTop: "3px" }}>
            <span className="SPP">
              {"{"}you formal, the{"}"}
            </span>
            <span className="SPP4">sie, Sie </span>
            <span
              className="SPP3"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) =>
                propse.Server_Verbs[Counter][CounterVVT].SIE.split(".")[1] ===
                undefined
                  ? null
                  : propse.Server_Verbs[Counter][CounterVVT].SIE.split(".")[1]
                      .length === 0
                  ? null
                  : handleClick(
                      event,
                      propse.Server_Verbs[Counter][CounterVVT].SIE.split(".")[1]
                    )
              }
            >
              {propse.Server_Verbs[Counter][CounterVVT].SIE.split(".")[0]}
            </span>
          </Box>
          <Box className="Sun-Buttons">
            <ArrowForwardRoundedIcon
              sx={{ cursor: "pointer" }}
              onClick={HandleNext}
            />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Viewverb;
