import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Letters from "../../../components/letters/Letters";
import Button from "../../../context/buttons/Button";
import AXIOS from "../../../context/api/AXIOS";

const Erstellen = ({ Server, GetData_Rule, OptionRUle }) => {
  const [GR, setGR] = useState("");
  const [EN, setEN] = useState("");
  const [Select, setDelect] = useState(0);
  const [Loading, setLoading] = useState(false);
  const HandleAdd = async () => {
    setLoading(true);
    const DATA = {
      Title: GR,
      Rule: EN,
    };
    if (GR.length !== 0 && EN.length !== 0) {
      await AXIOS.put(
        `/DEEP-GR-RULE/${Server === null ? 0 : Server.length}.json`,
        DATA
      )
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            GetData_Rule();
            setGR("");
            setEN("");
          }
        })
        .catch((error) => {
          console.log(error);
          alert(error);
          setLoading(false);
        });
    }
  };
  const HandleEdit = async () => {
    setLoading(true);
    const DATA = {
      Title: GR,
      Rule: EN,
    };
    if (GR.length !== 0 && EN.length !== 0) {
      await AXIOS.put(`/DEEP-GR-RULE/${OptionRUle.id}.json`, DATA)
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            GetData_Rule();
            setGR("");
            setEN("");
          }
        })
        .catch((error) => {
          console.log(error);
          alert(error);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (OptionRUle !== null) {
      setGR(OptionRUle.option.Title);
      setEN(OptionRUle.option.Rule);
    }
  }, [OptionRUle]);
  return (
    <React.Fragment>
      <Box className="Wrppers">
        <Box className="Wrppers-Box">
          <Box sx={{ marginTop: "15px" }}></Box>
          <Letters
            GR={Select === 0 ? GR : EN}
            setGR={Select === 0 ? setGR : setEN}
          />
          <input
            className="InputUben6"
            placeholder="Die erste Aussage"
            value={GR}
            onChange={(e) => setGR(e.target.value)}
            onFocus={() => setDelect(0)}
          />
          <textarea
            className="InputUben5"
            placeholder="Die erste Aussage"
            value={EN}
            onChange={(e) => setEN(e.target.value)}
            onFocus={() => setDelect(1)}
          />
          <Box className="ADD">
            {Loading ? (
              <Button Title="Loading ..." W="135" H="45" C="#ff023f" R="0" />
            ) : OptionRUle === null ? (
              <Button
                Title="ADD"
                W="135"
                H="45"
                C="#ff023f"
                R="0"
                onClick={HandleAdd}
              />
            ) : (
              <Button
                Title="EDIT"
                W="135"
                H="45"
                C="#ff023f"
                R="0"
                onClick={HandleEdit}
              />
            )}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Erstellen;
