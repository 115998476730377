import { Box } from "@mui/material";
import React from "react";
import Statements from "../../../context/statements/Statements";
import Button from "../../../context/buttons/Button";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

const Rezension = ({
  Ref,
  ServerUpdate,
  Handle,
  setAppsubmenu,
  setOptionToSelect,
  RefIndex,
  setRefIIndex,
  SearchNotFoundOption,
  setSearchNotFoundOption,
}) => {
  return (
    <React.Fragment>
      <Box className="Wrppers">
        <Box className="Wrppers-Box" onClick={() => setRefIIndex(null)}>
          {ServerUpdate.length === 0 ? (
            <Box className="Empty">
              <span style={{ marginTop: "50px", marginBottom: "15px" }}>
                Es sind keine Informationen in der Datenbank vorhanden
              </span>
              {/* <Button
                Title="ADD"
                W="80"
                H="35"
                C="#16615c"
                R="5"
                //onClick={HandleAdd}
              /> */}
            </Box>
          ) : SearchNotFoundOption === null ? (
            <React.Fragment>
              <Box sx={{ marginTop: "20px" }}></Box>
              {ServerUpdate.map((option, index) => (
                <Statements
                  key={index}
                  option={option}
                  index={index}
                  Ref={Ref}
                  ServerUpdate={ServerUpdate}
                  Handle={Handle}
                  setAppsubmenu={setAppsubmenu}
                  setOptionToSelect={setOptionToSelect}
                  RefIndex={RefIndex}
                />
              ))}
              <Box sx={{ marginTop: "100px" }}></Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box sx={{ marginTop: "20px" }}></Box>
              <Statements
                option={SearchNotFoundOption}
                Ref={Ref}
                ServerUpdate={ServerUpdate}
                Handle={Handle}
                setAppsubmenu={setAppsubmenu}
                setOptionToSelect={setOptionToSelect}
                RefIndex={RefIndex}
              />
              <Box className="BackRezension">
                <ArrowBackRoundedIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setSearchNotFoundOption(null)}
                />
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Rezension;
