import { Box } from "@mui/material";
import React, { useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import GetAppIcon from "@mui/icons-material/GetApp";
import Popupshape from "../popup/Popupshape";

const Letters = ({ GR, setGR }) => {
  const [TextTransform, setTextTransform] = useState("lowercase");
  const Letters = ["ö", "ü", "ä", "é"];
  const handleClick = (InvitationLink) => {
    //navigator.clipboard.writeText(InvitationLink);
    //console.log(propse.GR);
    setGR(GR + InvitationLink);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick_Popup = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleShapes = (e) => {
    setGR(GR + " " + e + " ");
  };
  const Shapes = [
    "☘",
    "💗",
    "✅",
    "✈",
    "✔",
    "✤",
    "✺",
    "✽",
    "☀",
    "❌",
    "❖",
    "➙",
    "⬅",
    "➕",
    "➊",
    "➋",
    "➌",
    "❹",
    "❺",
    "❻",
    "❼",
    "❽",
    "❾",
    "❿",
    "☀",
    "☂",
    "♀",
    "♂",
    "♁",
    "♚",
    "♣",
    "⛔",
    "→",
    "←",
  ];
  return (
    <React.Fragment>
      <Popupshape
        Text={Shapes}
        anchorEl={anchorEl}
        open={open}
        handleClick={handleClick_Popup}
        handleClose={handleClose}
        handleShapes={handleShapes}
      />
      <Box className="Letters">
        <Box>
          <FileUploadIcon
            className="SwipeUpIcon"
            sx={{
              fontSize: "1rem",
              display: TextTransform === "uppercase" ? "none" : "flex",
            }}
            onClick={() => setTextTransform("uppercase")}
          />
          <GetAppIcon
            className="SwipeUpIcon"
            sx={{
              fontSize: "1rem",
              display: TextTransform === "lowercase" ? "none" : "flex",
            }}
            onClick={() => setTextTransform("lowercase")}
          />
          <span
            style={{ textTransform: TextTransform }}
            onClick={() =>
              handleClick(
                TextTransform === "uppercase"
                  ? Letters[0].toUpperCase()
                  : Letters[0].toLowerCase()
              )
            }
          >
            {Letters[0]}
          </span>
          <span
            style={{ textTransform: TextTransform }}
            onClick={() =>
              handleClick(
                TextTransform === "uppercase"
                  ? Letters[1].toUpperCase()
                  : Letters[1].toLowerCase()
              )
            }
          >
            {Letters[1]}
          </span>
          <span
            style={{ textTransform: TextTransform }}
            onClick={() =>
              handleClick(
                TextTransform === "uppercase"
                  ? Letters[2].toUpperCase()
                  : Letters[2].toLowerCase()
              )
            }
          >
            {Letters[2]}
          </span>
          <span
            style={{ textTransform: TextTransform }}
            onClick={() =>
              handleClick(
                TextTransform === "uppercase"
                  ? Letters[3].toUpperCase()
                  : Letters[3].toLowerCase()
              )
            }
          >
            {Letters[3]}
          </span>
          <span onClick={() => handleClick("ß")}>ß</span>
          {/* <span onClick={() => handleClick(" ☘️ ")}>☘️</span>
        <span onClick={() => handleClick(" 💗 ")}>💗</span>
        <span onClick={() => handleClick(" ✅ ")}>✅</span>
        <span onClick={() => handleClick(" ✈ ")}>✈</span>
        <span onClick={() => handleClick(" ✔ ")}>✔</span>
        <span onClick={() => handleClick(" ✤ ")}>✤</span>
        <span onClick={() => handleClick(" ✺ ")}>✺</span>
        <span onClick={() => handleClick(" ✽ ")}>✽</span>
        <span onClick={() => handleClick(" ☀️ ")}>☀️</span>
        <span onClick={() => handleClick(" ❌ ")}>❌</span>
        <span onClick={() => handleClick(" ❖ ")}>❖</span>
        <span onClick={() => handleClick(" ➙ ")}>➙</span>
        <span onClick={() => handleClick(" ⬅ ")}>⬅</span>➺ ➕➊➋➌❹❺❻❼❽❾❿☀☂♀♁♂♚♣⛔←→*/}
        </Box>
        <span
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(event) => handleClick_Popup(event, ` 💗 ❌ `)}
        >
          =
        </span>
      </Box>
    </React.Fragment>
  );
};

export default Letters;
