import { Box } from "@mui/material";
import React, { useState } from "react";
import DialpadIcon from "@mui/icons-material/Dialpad";
import Back from "@mui/icons-material/ArrowBackIosRounded";

const Set = ({ Server, setAppsubmenu, setOptionRUle }) => {
  const [Show, setShow] = useState(null);
  const Handle = (e, index) => {
    const E = { option: e, id: index };
    setAppsubmenu("32");
    setOptionRUle(E);
  };
  return (
    <React.Fragment>
      <Box className="Wrppers">
        <Box className="Wrppers-Box">
          <Box sx={{ marginTop: "15px" }}></Box>
          {Show === null ? (
            Server !== null &&
            Server.map((option, index) => (
              <Box key={index} className="GR">
                <Box className="GRT2" onClick={() => setShow(option)}>
                  <span>{option.Title}</span>
                </Box>
                <DialpadIcon
                  sx={{ fontSize: "0.9rem" }}
                  className="Icons"
                  onClick={() => Handle(option, index)}
                />
              </Box>
            ))
          ) : (
            <React.Fragment>
              <Box className="Show-Set">
                <Back
                  sx={{ fontSize: "1rem", cursor: "pointer" }}
                  onClick={() => setShow(null)}
                />
                <span className="Show-Set-Title">
                  {Show !== null ? Show.Title : null}
                </span>
              </Box>
              <textarea
                className="InputUben7"
                placeholder="Die erste Aussage"
                value={Show !== null ? Show.Rule : ""}
                readOnly
              />
            </React.Fragment>
          )}
          <Box sx={{ marginTop: "80px" }}></Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Set;
