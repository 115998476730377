import "./App.css";
import React, { createRef, useEffect, useState } from "react";
import Header from "./components/header/Header";
import { Box } from "@mui/material";
import SunWrapper from "./components/sunwrapper/SunWrapper";
import SubHeader from "./components/subheader/SubHeader";
import Addverb from "./pages/verb/addverb/Addverb";
import Shape from "./components/shape/Shape";
import AXIOS from "./context/api/AXIOS";
import Viewverb from "./pages/verb/view/Viewverb";
import Rezension from "./pages/uben/rezension/Rezension";
import Leitner from "./pages/uben/leitner/Leitner";
import Zufallig from "./pages/uben/zufallig/Zufallig";
import AddStatements from "./pages/uben/addstatements/AddStatements";
import Set from "./pages/regeln/set/Set";
import Erstellen from "./pages/regeln/erstellen/Erstellen";

function App() {
  const [Appmenu, setAppmenu] = useState("");
  const [Appsubmenu, setAppsubmenu] = useState("");
  const [Server_Verbs, setServer_Verbs] = useState([]);
  const [Server_GR, setServer_GR] = useState([]);
  const [Server_RULE, setServer_RULE] = useState([]);
  const [Ref, setRef] = useState([]);
  const [RefIndex, setRefIIndex] = useState(null);
  const [CounterStatement, setCounterStatement] = useState(0);
  const [ServerUpdate, setServerUpdate] = useState([]);
  const [OptionToSelect, setOptionToSelect] = useState("");
  const [Score, setScore] = useState(0);
  const [dataHolderSearch, setDataHolderSearch] = useState([]);
  const [Searchtext, setSearchtext] = useState("");
  const [Searching, setSearching] = useState(false);
  const [SearchNotFoundOption, setSearchNotFoundOption] = useState(null);
  const [OptionRUle, setOptionRUle] = useState(null);
  let Server_Verbs_Oject = [];
  const GetData_Verbs = async () => {
    await AXIOS.get("/DEEP-GR-VERBS.json")
      .then((response) => {
        if (response.status === 200) {
          setServer_Verbs(response.data);
          GetData_Statement();
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  };
  const GetData_Statement = async () => {
    await AXIOS.get("/DEEP-GR.json")
      .then((response) => {
        if (response.status === 200) {
          setServer_GR(response.data);
          GetData_Counter(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  };
  const GetData_Counter = async (SERVER) => {
    await AXIOS.get("/DEEP-GR-COUNTER.json")
      .then((response) => {
        if (response.status === 200) {
          GetData_Score();
          if (response.data === null) {
            setCounterStatement(0);
          } else {
            let NI = [];
            for (let x = 0; x <= response.data - 1; x++) {
              NI.push(SERVER[x]);
              setServerUpdate(NI);
            }
            setCounterStatement(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  };
  const GetData_Score = async () => {
    await AXIOS.get("/DEEP-GR-SCORE.json")
      .then((response) => {
        if (response.status === 200) {
          GetData_Rule();
          if (response.data === null) setScore(0);
          else setScore(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  };
  const GetData_Rule = async () => {
    await AXIOS.get("/DEEP-GR-RULE.json")
      .then((response) => {
        if (response.status === 200) {
          setServer_RULE(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  };
  const HandleStatement = async () => {
    if (Server_GR !== null && CounterStatement < Server_GR.length) {
      if (CounterStatement === 0) {
        const newIndex = [...ServerUpdate, Server_GR[CounterStatement]];
        setServerUpdate(newIndex);
        setCounterStatement(CounterStatement + 1);
      } else {
        await AXIOS.put(`/DEEP-GR-COUNTER.json`, CounterStatement)
          .then((response) => {
            if (response.status === 200) {
              const newIndex = [...ServerUpdate, Server_GR[CounterStatement]];
              setServerUpdate(newIndex);
              setCounterStatement(CounterStatement + 1);
            }
          })
          .catch((error) => {
            console.log(error);
            alert(error);
          });
      }
    }
  };
  const onChangeSearch = (event) => {
    event.preventDefault();
    if (Appmenu === "2") {
      const searching = Server_GR.filter((item) => {
        const FCS = item.EN + " " + item.GR + " " + item.SP;
        const filter = FCS.toUpperCase();
        const user = event.target.value.toUpperCase();
        return filter.indexOf(user) > -1;
      });
      if (event.target.value.length !== 0) setDataHolderSearch(searching);
      else setDataHolderSearch([]);
      setSearchtext(event.target.value);
    }
  };
  const HandleSunWrapper = () => {
    setAppmenu("2");
    HandleStatement();
  };
  const HandleClickSelectSearch = (ID, Option) => {
    if (ID < CounterStatement) {
      Ref[ID].current.scrollIntoView({ behavior: "smooth" });
      setDataHolderSearch([]);
      setSearchtext("");
      setRefIIndex(ID);
      setSearchtext("");
      setDataHolderSearch([]);
      setSearching(false);
    } else {
      setDataHolderSearch([]);
      setSearchtext("");
      setSearchtext("");
      setDataHolderSearch([]);
      setSearching(false);
      setSearchNotFoundOption(Option);
      setAppsubmenu("21");
    }
  };
  useEffect(() => {
    GetData_Verbs();
  }, []);
  useEffect(() => {
    if (Server_GR !== null) setRef(Server_GR.map(() => createRef()));
  }, [Server_GR]);

  return (
    <React.Fragment>
      <Box className="Wrapper">
        <Header
          Appmenu={Appmenu}
          setAppmenu={setAppmenu}
          HandleSunWrapper={HandleSunWrapper}
          setDataHolderSearch={setDataHolderSearch}
          setSearchtext={setSearchtext}
          setSearching={setSearching}
        />
        {Appmenu.length === 0 ? (
          <SunWrapper HandleSunWrapper={HandleSunWrapper} />
        ) : (
          <React.Fragment>
            <SubHeader
              Appmenu={Appmenu}
              Appsubmenu={Appsubmenu}
              setAppsubmenu={setAppsubmenu}
              setOptionToSelect={setOptionToSelect}
              onChangeSearch={onChangeSearch}
              Searchtext={Searchtext}
              setDataHolderSearch={setDataHolderSearch}
              setSearchtext={setSearchtext}
              Searching={Searching}
              setSearching={setSearching}
              setOptionRUle={setOptionRUle}
            />
            <Shape
              Appmenu={Appmenu}
              Appsubmenu={Appsubmenu}
              setAppsubmenu={setAppsubmenu}
            />
          </React.Fragment>
        )}
        <Box className="SearchBox">
          {dataHolderSearch.length === 0 ? null : (
            <Box className="SearchBoxText">
              {dataHolderSearch.map((option, index) => (
                <Box
                  key={index}
                  className="SBT"
                  onClick={() => HandleClickSelectSearch(option.ID, option)}
                >
                  <Box className="SearchItems">
                    <span>{option.GR}</span>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        {Appmenu === "1" && Appsubmenu === "12" ? (
          <Addverb
            setAppsubmenu={setAppsubmenu}
            Server_Verbs={Server_Verbs}
            Server_Verbs_Oject={Server_Verbs_Oject}
            GetData_Verbs={GetData_Verbs}
          />
        ) : Appmenu === "1" && Appsubmenu === "11" ? (
          <React.Fragment>
            {Server_Verbs === null ? (
              <Box className="Empty">
                <Box>" Keine Daten verfügbar "</Box>
              </Box>
            ) : (
              <Viewverb Server_Verbs={Server_Verbs} />
            )}
          </React.Fragment>
        ) : Appmenu === "2" && Appsubmenu === "21" ? (
          <Rezension
            ServerUpdate={ServerUpdate}
            Ref={Ref}
            Handle={HandleStatement}
            setAppsubmenu={setAppsubmenu}
            setOptionToSelect={setOptionToSelect}
            RefIndex={RefIndex}
            setRefIIndex={setRefIIndex}
            SearchNotFoundOption={SearchNotFoundOption}
            setSearchNotFoundOption={setSearchNotFoundOption}
          />
        ) : Appmenu === "2" && Appsubmenu === "22" ? (
          <Leitner />
        ) : Appmenu === "2" && Appsubmenu === "23" ? (
          <Zufallig
            Server={Server_GR}
            Score={Score}
            setScore={setScore}
            GetData_Score={GetData_Score}
          />
        ) : Appmenu === "2" && Appsubmenu === "AddUben" ? (
          <AddStatements
            Counter={Server_GR !== null ? Server_GR.length : 0}
            GetData_Statement={GetData_Statement}
            Option={OptionToSelect}
            setAppsubmenu={setAppsubmenu}
          />
        ) : Appmenu === "3" && Appsubmenu === "31" ? (
          <Set
            Server={Server_RULE}
            setAppsubmenu={setAppsubmenu}
            setOptionRUle={setOptionRUle}
          />
        ) : Appmenu === "3" && Appsubmenu === "32" ? (
          <Erstellen
            Server={Server_RULE}
            GetData_Rule={GetData_Rule}
            OptionRUle={OptionRUle}
          />
        ) : null}
      </Box>
    </React.Fragment>
  );
}

export default App;
