import { Box } from "@mui/material";
import React from "react";
import Verb from "@mui/icons-material/Spa";
import Uben from "@mui/icons-material/SportsBasketball";
import Regeln from "@mui/icons-material/Visibility";

const Header = (propse) => {
  const Handle = (Option) => {
    propse.setAppmenu(Option);
    propse.setDataHolderSearch([]);
    propse.setSearchtext("");
    propse.setSearching(false);
  };

  return (
    <Box className="Header">
      <Box
        className="HeaderMenu"
        onClick={() => Handle("1")}
        sx={{ color: propse.Appmenu === "1" ? "#ed013e" : null }}
      >
        <Verb sx={{ fontSize: "1.15rem" }} />
        <span className="Title">Verb</span>
      </Box>
      <Box
        className="HeaderMenu"
        onClick={propse.HandleSunWrapper}
        sx={{ color: propse.Appmenu === "2" ? "#ed013e" : null }}
      >
        <Uben sx={{ fontSize: "1.15rem" }} />
        <span className="Title">Üben</span>
      </Box>
      <Box
        className="HeaderMenu"
        onClick={() => Handle("3")}
        sx={{ color: propse.Appmenu === "3" ? "#ed013e" : null }}
      >
        <Regeln sx={{ fontSize: "1.15rem" }} />
        <span className="Title">Regeln</span>
      </Box>
    </Box>
  );
};

export default Header;
