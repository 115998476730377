import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Letters from "../../../components/letters/Letters";
import ShowerRoundedIcon from "@mui/icons-material/ShowerRounded";
import Button from "../../../context/buttons/Button";
import AXIOS from "../../../context/api/AXIOS";

const Zufallig = ({ Server, Score, setScore, GetData_Score }) => {
  const [GR, setGR] = useState("");
  const [EN, setEN] = useState("");
  // const [Counter, setCounter] = useState(0);
  const [Correct, setCorrect] = useState(null);
  const [Loading, setLoading] = useState(false);

  // function randomNumberBetween(min, max) {
  //   const X = Math.floor(Math.random() * (max - min + 1)) + min;
  //   setCounter(X);
  //   return X;
  // }
  // const Check = async () => {
  //   setLoading(true);
  //   if (EN.length !== 0) {
  //     const OnlyEN = EN.trim()
  //       .replace(/[^a-zA-ZäöüÄÖÜß]/g, "")
  //       .toUpperCase();
  //     const OnlyGR = Server[Score].GR.trim()
  //       .replace(/[^a-zA-ZäöüÄÖÜß]/g, "")
  //       .toUpperCase();
  //     if (OnlyGR === OnlyEN) {
  //       await AXIOS.put(
  //         `/DEEP-GR-SCORE.json`,
  //         Score + 1 === 0 ? "0" : Score + 1
  //       )
  //         .then((response) => {
  //           if (response.status === 200) {
  //             setLoading(false);
  //             const X = randomNumberBetween(0, Server.length);
  //             setGR(Server[Score + 1].EN);
  //             setEN("");
  //             setCorrect(null);
  //             GetData_Score();
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           setLoading(false);
  //           alert(error);
  //         });
  //     } else {
  //       setCorrect(false);
  //       setLoading(false);
  //       await AXIOS.put(
  //         `/DEEP-GR-SCORE.json`,
  //         Score - 1 === 0 ? "0" : Score - 1
  //       )
  //         .then((response) => {
  //           if (response.status === 200) {
  //             setLoading(false);
  //             GetData_Score();
  //             setCorrect(false);
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           setLoading(false);
  //           alert(error);
  //         });
  //     }
  //   }
  // };
  // const Set = async () => {
  //   setLoading(true);
  //   await AXIOS.put(`/DEEP-GR-SCORE.json`, Score - 1 === 0 ? "0" : Score - 1)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setLoading(null);
  //         GetData_Score();
  //         setEN(Server[Score].GR);
  //         setCorrect(null);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //       alert(error);
  //     });
  // };
  // const OnChangeArea = (e) => {
  //   setEN(e.target.value);
  //   setCorrect(null);
  // };
  // useEffect(() => {
  //   const X = randomNumberBetween(0, Server.length);
  //   setGR(Server[Score].EN);
  // }, []);
  const Check = async () => {
    if (EN.length !== 0) {
      const OnlyEN = EN.trim()
        .replace(/[^a-zA-ZäöüÄÖÜß]/g, "")
        .toUpperCase();
      const OnlyEN1 = OnlyEN + "?!.-";
      const OnlyGR = Server[Score].GR.trim()
        .replace(/[^a-zA-ZäöüÄÖÜß]/g, "")
        .toUpperCase();
      const OnlyGR1 = OnlyGR + "?!.-";
      if (OnlyEN1 === OnlyGR1) {
        setLoading(true);
        await AXIOS.put(`/DEEP-GR-SCORE.json`, Score + 1)
          .then((response) => {
            if (response.status === 200) {
              setLoading(false);
              setScore(Score + 1);
              setGR(Server[Score + 1].EN);
              setEN("");
              setCorrect(null);
              GetData_Score();
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            alert(error);
          });
      } else {
        setCorrect(false);
      }
    }
  };
  const Set = async () => {
    setEN(Server[Score].GR);
    setCorrect(null);
  };
  const OnChangeArea = (e) => {
    setEN(e.target.value);
    setCorrect(null);
  };
  useEffect(() => {
    if (Server !== null) setGR(Server[Score].EN);
  }, []);

  return (
    <React.Fragment>
      {Server !== null ? (
        <Box className="Wrppers">
          <Box className="Wrppers-Box">
            <Letters GR={EN} setGR={setEN} />
            <Box className="GR">
              <Box className="GRT">{GR}</Box>
            </Box>
            <Box className="Zufallig">
              <ShowerRoundedIcon className="ShowerRoundedIcon" onClick={Set} />
              {/* <Box className="Score">{Score}</Box> */}
              <textarea
                className="InputUben4"
                placeholder="Die erste Aussage"
                style={{
                  color:
                    Correct === null ? "rgb(0, 0, 0, 0.7)" : "rgb(255, 2, 63)",
                }}
                value={EN}
                onChange={(e) => OnChangeArea(e)}
              />
            </Box>
            <Box className="ADD">
              {Loading ? (
                <Button Title="Loading ..." W="135" H="45" C="#ff023f" R="0" />
              ) : (
                <Button
                  Title="CHECk"
                  W="135"
                  H="45"
                  C="#ff023f"
                  R="0"
                  onClick={Check}
                />
              )}
            </Box>
          </Box>
        </Box>
      ) : null}
    </React.Fragment>
  );
};

export default Zufallig;
